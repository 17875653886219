import { useCallback, useEffect, useState } from 'react'
import {
  Breadcrumbs,
  Button,
  Checkbox,
  ContainerScreen,
  Input,
  ViewEntities,
} from 'components'

import { useForm } from 'react-hook-form'

import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { holidaySchema } from '../../schemas/holidaySchema'
import {
  COVERAGE_HOLIDAY_EN_PT,
  Months,
  coverageHoliday,
  holidaySelectors,
  months,
} from '../../types'
import { getDays, getYears } from '../../utilities/utils'
import { useGetCities, useGetStates } from 'shared/hooks/services'
import { State } from 'services/address/state'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { City } from 'services/address/city/types'

import { Sections, ViewEntityContent } from 'components/ViewEntities/types'
import { ReactComponent as PlusSignIcon } from 'assets/svg/plusSign.svg'
import { joiResolver } from '@hookform/resolvers/joi'
import styles from './HolidayForm.module.scss'
import { HolidayCoverageType, HolidayPayload } from 'services/holiday/types'
import { formatDecimal } from 'utilities/date'
import { HolidayFormData } from 'domains/holiday/components/HolidayForm/types'
import { paths } from 'routes'

type HolidayFormProps = {
  holiday?: {
    name: string
    repeatable: boolean
    day: number
    month: number
    year?: number
    coverageType: HolidayCoverageType
    coverages?: ViewEntityContent[]
  }
  onSubmit: (data: HolidayPayload) => void
  onGoBack: () => void
  readonly?: boolean
}

const emptyEntity: holidaySelectors = {
  coverage: {
    city: null,
    state: null,
  },
}

export const HolidayForm = ({
  onGoBack,
  onSubmit,
  readonly,
  holiday,
}: HolidayFormProps) => {
  const [selectedStateId, setSelectedStateId] = useState<string>('')

  const [selectedSelector, setSelectedSelector] =
    useState<holidaySelectors>(emptyEntity)

  const [selectorList, setSelectorList] = useState<Sections>(
    new Map<string, ViewEntityContent[]>(),
  )

  const form = useForm<HolidayFormData>({
    mode: 'onChange',
    resolver: joiResolver(holidaySchema),
    defaultValues: {
      name: holiday?.name,
      repeatable: !!holiday?.repeatable,
      coverageType: holiday?.coverageType,
      day: holiday?.day,
      month: holiday?.month,
      year: holiday?.year,
    },
  })

  const { register, watch, setValue, formState, getValues } = form

  const repeatable = watch('repeatable')
  const day = watch('day')
  const month = watch('month')
  const year = watch('year')
  const coverageType = watch('coverageType')
  const coverage = watch('coverage')

  const [stateFilter, setStateFilter] = useState('')
  const {
    isError: isErrorStates,
    isFetching: isFetchingStates,
    fetchNextPage: fetchNextStatesPage,
    states,
  } = useGetStates(coverageType === 'REGIONAL', stateFilter)

  const [accountFilter, setAccountFilter] = useState('')
  const {
    isError: isErrorCities,
    isFetching: isFetchingCities,
    cities,
    fetchNextCitiesPage,
  } = useGetCities(selectedStateId, accountFilter)

  const addEntity = useCallback(() => {
    const { coverage: selectedCoverage } = selectedSelector

    const currentEntity = new Map(selectorList)

    if (selectedCoverage?.city && selectedCoverage?.state) {
      currentEntity.set(selectedCoverage.city.id, [
        {
          id: selectedCoverage.city.id,
          title: `${selectedCoverage.city.name} - ${selectedCoverage.state.acronym}`,
          subtitle: 'Cidade',
        },
      ])

      setValue(
        'coverage',
        {
          ...coverage,
          cities: [...(coverage?.cities || []), selectedCoverage.city.id],
        },
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      )
    }

    if (selectedCoverage?.state && !selectedCoverage?.city) {
      currentEntity.set(selectedCoverage.state.id, [
        {
          id: selectedCoverage.state.id,
          title: selectedCoverage.state.name,
          subtitle: 'Estado',
        },
      ])

      setValue(
        'coverage',
        {
          ...coverage,
          states: [...(coverage?.states || []), selectedCoverage.state.id],
        },
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      )
    }

    setSelectorList(currentEntity)
  }, [coverage, selectedSelector, selectorList, setValue])

  const removeEntity = useCallback(
    (entity: ViewEntityContent) => {
      const currentEntity = new Map(selectorList)
      currentEntity.delete(entity.id)

      if (entity.subtitle === 'Cidade') {
        setValue(
          'coverage',
          {
            ...coverage,
            cities: coverage?.cities?.filter((cityId) => cityId !== entity.id),
          },
          {
            shouldDirty: true,
            shouldValidate: true,
          },
        )
      } else {
        setValue(
          'coverage',
          {
            ...coverage,
            states: coverage?.states?.filter(
              (stateId) => stateId !== entity.id,
            ),
          },
          {
            shouldDirty: true,
            shouldValidate: true,
          },
        )
      }

      setSelectorList(currentEntity)
    },
    [selectorList, setValue, coverage],
  )

  useEffect(() => {
    register('coverage')
    if (!holiday) return

    const currentEntity = new Map(selectorList)

    holiday.coverages?.map((coverage) => {
      currentEntity.set(coverage.id, [
        {
          id: coverage.id,
          title: coverage.title,
          subtitle: coverage.subtitle,
        },
      ])

      const prevValue: HolidayFormData['coverage'] = getValues('coverage')

      if (coverage.formKey === 'cities') {
        setValue('coverage', {
          states: prevValue?.states,
          cities: [...(prevValue?.cities || []), coverage.id],
        })
      }

      if (coverage.formKey === 'states') {
        setValue('coverage', {
          cities: prevValue?.cities,
          states: [...(prevValue?.states || []), coverage.id],
        })
      }
    })

    setSelectorList(currentEntity)
  }, [])

  return (
    <ContainerScreen
      clickable
      divider={false}
      renderBreadcrumbs={
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Feriados', href: paths.configuration.holiday.list },
            { title: 'Novo feriado', href: '#' },
          ]}
        />
      }
    >
      <div className={styles.separator} />
      <div className={styles.formWrapper}>
        <Input
          label="Nome"
          placeholder="Nome do Feriado"
          id="name"
          autoComplete="off"
          {...register('name')}
          maxLength={60}
          onChange={(event) => {
            setValue('name', event.target.value, {
              shouldDirty: true,
              shouldValidate: true,
            })
          }}
        />

        <Checkbox
          key={'repeatable'}
          checked={repeatable}
          id={'repeatable'}
          {...register('repeatable')}
          label={'Feriado com data fixa'}
          onChange={(event) => {
            setValue('year', 0, { shouldValidate: true })
            setValue('repeatable', Boolean(event.target.checked), {
              shouldDirty: true,
              shouldValidate: true,
            })
          }}
        />
        <div className={styles.date}>
          <h2>Data</h2>
          <div className={styles.dateWrapper}>
            <Combobox
              id="day"
              className={styles.day}
              placeholder="Dia"
              value={day ? formatDecimal(day) : ''}
              {...register('day')}
              items={getDays()}
              onChange={(selected) =>
                setValue('day', Number(selected), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
            />
            <Combobox
              id="month"
              placeholder="Mês"
              {...register('month')}
              className={styles.month}
              value={Object.keys(months)[month - 1]}
              items={Object.keys(months)}
              onChange={(selected) =>
                setValue('month', months[selected as Months], {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
            />

            <Combobox
              disabled={repeatable}
              id="ano"
              placeholder="Ano"
              {...register('year')}
              className={styles.year}
              value={year ? year.toString() : ''}
              items={getYears()}
              onChange={(selected) =>
                setValue('year', Number(selected), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
            />
          </div>
        </div>

        <Combobox
          id="coverage"
          label={{
            text: 'Abrangência',
          }}
          value={COVERAGE_HOLIDAY_EN_PT[coverageType]}
          items={Object.values(COVERAGE_HOLIDAY_EN_PT)}
          {...register('coverageType')}
          onChange={(selected) => {
            setValue('coverage', undefined, {
              shouldDirty: true,
              shouldValidate: true,
            })
            setValue(
              'coverageType',
              selected === 'Regional'
                ? coverageHoliday.REGIONAL
                : coverageHoliday.NATIONAL,
              {
                shouldDirty: true,
                shouldValidate: true,
              },
            )
            setSelectorList(new Map())
          }}
        />

        {coverageType === 'REGIONAL' && (
          <>
            <Combobox
              id="state"
              label={{
                text: 'Estado',
              }}
              {...register('coverage')}
              items={parseDataToComboboxV2(states || [], 'name')}
              value={
                selectedSelector.coverage?.state
                  ? { label: 'name', value: selectedSelector.coverage.state }
                  : undefined
              }
              onChange={(selected) => {
                const state = selected as ComboboxItem<State>
                setSelectedStateId(state.value.id)
                setSelectedSelector({
                  coverage: {
                    state: state.value,
                    city: null,
                  },
                })
              }}
              isLoading={isFetchingStates}
              isError={isErrorStates}
              onSearch={(state) => setStateFilter(state)}
              onEndReached={fetchNextStatesPage}
            />
            <Combobox
              id="city"
              label={{
                text: 'Cidade',
              }}
              items={parseDataToComboboxV2(cities || [], 'name')}
              value={
                selectedSelector.coverage?.city
                  ? { label: 'name', value: selectedSelector.coverage?.city }
                  : undefined
              }
              onChange={(selected) => {
                const city = selected as ComboboxItem<City>
                setSelectedSelector((prev) => ({
                  ...prev,
                  coverage: {
                    state: prev.coverage?.state || null,
                    city: city.value,
                  },
                }))
              }}
              onSearch={(city) => setAccountFilter(city)}
              isLoading={isFetchingCities}
              isError={isErrorCities}
              onEndReached={fetchNextCitiesPage}
            />
          </>
        )}
        <>
          <div className={styles.addCoverage}>
            <Button
              id="add-coverage"
              buttonTitle="Adicionar abrangências"
              type="tertiary"
              className={styles.entityButton}
              icon={PlusSignIcon}
              onClick={addEntity}
              disabled={readonly}
            />
          </div>
        </>

        {selectorList.size > 0 && (
          <div className={styles.coverageWrapper}>
            <div aria-label="coverages-wrapper" className={styles.coverageList}>
              <ViewEntities
                onRemove={
                  !readonly ? (entity) => removeEntity(entity) : undefined
                }
                sections={selectorList}
              />
            </div>
            <h2 className={styles.totalAdded}>
              Total adicionado: {selectorList.size}
            </h2>
          </div>
        )}
      </div>

      <div className={styles.footer}>
        <Button
          buttonTitle="Voltar"
          onClick={onGoBack}
          type="secondary"
          width="172px"
        />
        <Button
          buttonTitle="Salvar"
          id="save-button"
          type="primary"
          width="172px"
          onClick={() => onSubmit(getValues())}
          disabled={!formState.isValid || !formState.isDirty}
        />
      </div>
    </ContainerScreen>
  )
}
